<template>
  <div>
    <div class="name" v-if="View">
      <div class="mains">
        <div class="titleBg"></div>
        <div class="title">请输入笔名</div>
        <input type="text" v-model="name" :disabled="names">
        <div class="title">校友身份选择</div>
        <div class="text" @click="openAlumnus">{{ AlumnusText }}<span v-bind:class="{openTop:Alumnus}"></span></div>
        <div class="title">选择入校年份</div>
        <div class="text" @click="openGotoTime">{{ GotoTimeText }}<span v-bind:class="{openTop:GotoTime}"></span></div>
        <div class="ok" @click="setName">提交</div>
      </div>
    </div>
    <div class="Picker" v-if="Alumnus">
      <van-picker
          class="mainInfo"
          title="请选择校友身份"
          show-toolbar
          :columns="AlumnusData"
          @confirm="onConfirm"
          @cancel="closecancel"
      />
    </div>
    <div class="Picker" v-if="GotoTime">
      <van-picker
          class="mainInfo"
          title="请选择年份"
          show-toolbar
          :columns="GotoTimeData"
          @confirm="onConfirms"
          @cancel="closecancel"
      />
    </div>
    <load ref="load"></load>
  </div>
</template>

<script>
import {Dialog, Notify, Picker} from "vant";
import {setName} from "../api/login";
import load from '../components/load'

export default {
  data() {
    return {
      View: false,
      userInfo: {},
      name: '',
      names: true,
      Alumnus: false,
      AlumnusData: ['校友', '非校友'],
      AlumnusText: '未选择',
      GotoTime: false,
      GotoTimeData: [],
      GotoTimeText: '未选择',
    }
  },
  created() {
    this.init()
  },
  components: {
    [Notify.Component.name]:
    Notify.Component,
    [Picker.name]:
    Picker,
    load
  },
  methods: {
    open(userInfo) {
      this.userInfo = userInfo
      this.View = true
      let Name = userInfo.Name
      if (Name) {
        this.name = Name
        this.names = true
      } else {
        this.names = false
      }
    },
    onConfirm(value) {
      this.AlumnusText = value
      this.closecancel()
    },
    onConfirms(value) {
      this.GotoTimeText = value
      this.closecancel()
    },
    init() {
      let newYear = 2022
      let add = []
      for (let i = 0; i <= 125; i++) {
        let text = newYear - i
        add.push(text + '级')
      }
      this.GotoTimeData = add

    },
    openAlumnus() {
      this.Alumnus = true
    },
    openGotoTime() {
      this.GotoTime = true
    },
    closecancel() {
      this.Alumnus = false
      this.GotoTime = false
    },
    setName() {
      let Name = this.name
      let AlumnusText = this.AlumnusText
      let GotoTimeText = this.GotoTimeText
      if (Name.length > 10) {
        Dialog.alert({
          message: '笔名不能超过十个字',
        });
      } else if (GotoTimeText && AlumnusText && Name) {
        let _id = this.userInfo._id
        this.$refs.load.open()
        setName({Name, _id, GotoTimeText, AlumnusText}).then(res => {
          this.$refs.load.close()
          if (res.code == 0) {
            if (res.first == 1) {
              this.$store.state.userInfo = res.userInfo
              this.$cookies.set('userInfo', res.userInfo)
              localStorage.setItem("user", JSON.stringify(res.userInfo));
               this.$router.push('/zju/introduce')
            } else {
              this.$store.state.userInfo = res.userInfo
              this.$cookies.set('userInfo', res.userInfo)
              localStorage.setItem("user", JSON.stringify(res.userInfo));
               this.$router.push('/zju/exhibit')
            }
          } else {
            Notify({type: 'warning', message: res.message});
          }
        }).catch(() => {
          this.$refs.load.close()
          Dialog.alert({
            message: '服务端故障，请与技术人员联系',
          });
        })
      } else {
        Dialog.alert({
          message: '缺少必填参数',
        });
      }
    },
  }
}
</script>

<style scoped lang="scss">
.name {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  .mains {
    height: 300px;
    width: 310px;
    background-color: rgba(249, 249, 249, 0.97);
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -155px;
    margin-top: -150px;

    .text {
      width: 245px;
      height: 30px;
      margin: 0 auto;
      background-color: #fff;
      font-size: 13px;
      line-height: 30px;
      text-indent: 1rem;
      box-shadow: 0 2px 7px 0 rgba(159, 159, 159, 0.5);
      position: relative;

      span {
        position: absolute;
        background-image: url("../assets/image/login/triangleBottom.png");
        background-size: 100% 100%;
        width: 15px;
        height: 15px;
        right: 10px;
        top: 6px;
      }
      .openTop{
        background-image: url("../assets/image/login/triangleTop.png");
      }
    }

    .titleBg {
      width: 100%;
      height: 20px;
    }

    .title {
      width: 245px;
      height: 30px;
      font-size: 13px;
      color: #8D8D8D;
      line-height: 30px;
      margin: 0 auto;
    }

    input {
      display: block;
      margin: 0 auto;
      margin-top: 10px;
      border: none;
      width: 250px;
      height: 40px;
      outline: none;
      font-size: 14px;
      text-indent: 1rem;
      padding: 0;
      box-shadow: 0px 2px 7px 0px rgba(159, 159, 159, 0.5);
    }

    .ok {
      width: 250px;
      height: 35px;
      line-height: 35px;
      background-color: #1359CF;
      color: #FFFFFF;
      text-align: center;
      margin: 0 auto;
      margin-top: 20px;
    }

    .reset {
      background-image: url("../assets/image/map/reset.png");
      background-size: 100% 100%;
      position: absolute;
      right: 10px;
      top: 5px;
      width: 15px;
      height: 15px;
    }

    .f {
      margin-top: 8px;
      font-size: 12px;
      color: #898989;
      text-indent: 1rem;
    }

  }
}


.Picker {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;

  .mainInfo {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
  }
}
</style>
