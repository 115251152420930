<template>
  <div class="main" v-if="View">
    <div class="nav">
      <div class="block in">手机号码</div>
      <span>|</span>
      <div class="block" @click="openEmail">电子邮箱</div>
    </div>
    <div class="main1">
      <span class="txt1">+86</span>
      <input v-model="Phone" placeholder="请输入手机号码"/>
    </div>
    <div class="main2">
      <input v-model="Code"/>
    </div>
    <div class="main3" @click="set">{{ StartText }}</div>
    <div class="main5" @click="login">立即登陆</div>
    <div class="main6">
      <input name="Fruit" type="checkbox" v-model="lawStart"/>
      <span class="word">我已阅读并同意<a @click="getlaw('protocol')">用户协议</a>及<a @click="getlaw('privacy')">隐私政策</a></span>
    </div>
    <law ref="law"></law>
    <load ref="load"></load>
  </div>
</template>

<script>
import load from '../components/load'
import law from "../views/law";
import {Dialog, Notify} from "vant";
import {inspectCode, setCode} from "../api/login";

export default {
  data() {
    return {
      Start:false,
      View: true,
      Phone: '',
      Code: '',
      StartText: '发送验证码',
      lawStart: false,
      time:0
    }
  },
  components: {
    law,
    load,
    [Notify.Component.name]:
    Notify.Component,
    [Dialog.Component.name]:
    Dialog.Component,
  },
  methods: {
    openPhone(){
      this.View=true
    },
    openEmail(){
      this.View=false
      this.$parent.openEmail()
    },
    getlaw(str) {
      this.$refs.law.init(str)
    },
    login() {
      if (!this.lawStart) {
        Dialog.alert({
          message: '请勾选协议政策',
        });
        return;
      }

      let PhoneMobile = this.Phone
      let Code = this.Code

      if (!PhoneMobile) {
        Dialog.alert({
          message: '请填写手机号码',
        });
        return;
      }

      if (!Code) {
        Dialog.alert({
          message: '请填写验证码',
        });
        return;
      }

      this.$refs.load.open()
      inspectCode({PhoneMobile, Code}).then(res => {
        this.$refs.load.close()
        if (res.code == 0) {
          let userInfo = res.userInfo
          localStorage.setItem("user", JSON.stringify(userInfo));
          let Name = userInfo.Name
          let GotoTimeText = userInfo.GotoTime
          let AlumnusText = userInfo.Alumnus
          if (Name && GotoTimeText && AlumnusText) {
            this.$store.state.userInfo = res.userInfo
            localStorage.setItem("user", JSON.stringify(userInfo));
             this.$router.push('/zju/exhibit')
          } else {
            this.$parent.openName(res.userInfo)
            localStorage.setItem("user", JSON.stringify(userInfo));
          }
        } else {
          Notify({type: 'warning', message: res.message});
        }
      }).catch(() => {
        this.$refs.load.close()
        Dialog.alert({
          message: '服务端故障，请与技术人员联系',
        });
      })
    },
    //发送验证码
    set() {
      let time = this.time
      if (!this.Phone) {
        Dialog.alert({
          message: '未输入手机号码',
        });
        return;
      }
      if (time == 0) {
        if (/^1(?:3\d|4[4-9]|5[0-35-9]|6[67]|7[013-8]|8\d|9\d)\d{8}$/.test(this.Phone)) {
          let PhoneMobile = this.Phone
          this.$refs.load.open()
          setCode({PhoneMobile}).then((res) => {
            this.$refs.load.close()
            if (res.code == 0) {
              Dialog.alert({
                message: res.message,
              });
              this.time = 60
              this.Start = true
              this.CodeTime = setInterval(() => {
                let times = this.time
                times = times - 1
                if (times == 0) {
                  clearInterval(this.CodeTime)
                  this.StartText = '请发送验证码'
                  this.time = 0
                  return
                }
                this.time = times
                this.StartText = times + '秒再次发送'
              }, 1000)
            } else {
              Dialog.alert({
                message: res.message,
              });
            }
          }).catch(() => {
            this.$refs.load.close()
            Dialog.alert({
              message: '服务端故障，请与技术人员联系',
            });
          })
        } else {
          Dialog.alert({
            message: '手机号码不符合规则',
          });
        }
      }
    },
  }
}
</script>

<style scoped lang="scss">
.main {
  height: 300px;
  width: 310px;
  background-color: rgba(249, 249, 249, 0.97);
  box-shadow: 0 1px 8px 0 rgba(143, 143, 143, 0.5);
  align-self: center;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -155px;
  margin-top: -150px;
  .nav {
    width: 267px;
    height: 40px;
    position: absolute;
    left: 20px;
    top: 20px;
    display: flex;
    justify-content: space-around;

    .block {
      width: 300px;
      height: 40px;
      text-align: center;
      color: #b5b5b5;
    }

    .in {
      font-weight: bold;
      color: #020202;
    }

    span {
      position: absolute;
      left: 131px;
    }
  }



  .main1 {
    height: 40px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 7px 0px rgba(159, 159, 159, 0.5);
    width: 267px;
    position: absolute;
    left: 20px;
    top: 60px;

    .txt1 {
      width: 40px;
      height: 40px;
      display: block;
      color: rgba(210, 210, 210, 1);
      font-size: 14px;
      line-height: 40px;
      position: absolute;
      left: 0;
      text-align: center;
    }

    input {
      top: 0;
      position: absolute;
      border: none;
      right: 5px;
      width: 210px;
      height: 40px;
      outline: none;
      font-size: 14px;
      padding: 0;
    }
  }

  .main2 {
    width: 139px;
    height: 40px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 7px 0px rgba(159, 159, 159, 0.5);
    position: absolute;
    left: 20px;
    top: 120px;

    input {
      top: 0;
      position: absolute;
      border: none;
      left: 0;
      width: 100%;
      height: 100%;
      outline: none;
      text-indent: 1rem;
      font-size: 13px;
      padding: 0;
    }
  }

  .main3 {
    width: 107px;
    height: 40px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 7px 0px rgba(159, 159, 159, 0.5);
    position: absolute;
    left: 180px;
    top: 120px;
    color: #1359CF;
    font-size: 14px;
    text-align: center;
    line-height: 40px;
  }

  .main4 {
    width: 264px;
    height: 30px;
    position: absolute;
    left: 25px;
    top: 150px;
    display: flex;
    justify-content: space-between;

    .txt {
      font-size: 14px;
      color: rgba(0, 0, 0, 1);
    }

    .info {
      font-size: 14px;
      color: rgba(173, 173, 173, 1);
    }
  }

  .main5 {
    height: 44px;
    background-color: #1359CF;
    color: #FFFFFF;
    box-shadow: 0px 2px 7px 0px rgba(159, 159, 159, 0.5);
    width: 267px;
    position: absolute;
    text-align: center;
    left: 20px;
    top: 180px;
    line-height: 44px;
    font-size: 14px;
  }

  .main6 {
    width: 257px;
    height: 24px;
    position: absolute;
    font-size: 14px;
    top: 245px;
    left: 20px;

    input {
      width: 20px;
      display: inline-block;
      margin-right: 5px;
    }

    a {
      color: rgba(29, 170, 196, 0.81);
      text-decoration: 1px underline;
    }
  }
}
</style>
